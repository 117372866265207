import React, {useEffect, useState} from 'react';
import ExampleForm from '../../components/Forms/ExampleForm';
import AdminNav from '../../utils/AdminNav';
import API from '../../utils/API';
import MyExamplesEx from './Update/MyExamplesEx';

function BlogManager(props) {

    return (
        <>
           <AdminNav/>
           <div className="container">
               <div className="row blog-post">
                   <ExampleForm />
               </div>
               <div className="row blog-post">
                   <MyExamplesEx/>
               </div>
           </div>
        </>
    );
}

export default BlogManager;