import React, { useState, useContext } from 'react';
import axios from "axios"
import {useHistory} from "react-router-dom"
import AlertContext from '../../utils/alertContext';


const SubForm = () => {
    const [email, setEmail] = useState("");


    const history = useHistory()
    const alert = useContext(AlertContext);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        axios
          .post("/api/email/", { email })
          .then((response) => {
            console.log(response.data);
            history.push("/blog");
          })
          .catch((err) => {
            console.log(err);
            alert.setAlert({
                message: "Please respond to each input!",
                type: "danger",
              });
          }); 
      };

    return (
    <>
    <form className="newUser" onSubmit={handleFormSubmit}>
        <div className="container free-form ">


            <div className="row formRow">
                <h1 className="text-center login-header text-dark mb-3">Subscribe to our Blog!</h1>
            </div>

           
                <div className="row mt-3 formRow px-3">

                    <div className="col-2">
                        <label htmlFor="email" className="astroForm-label text-dark">Email</label>
                    </div>

                    <div className="col-6">
                        <input
                          id="email"
                          type="email"
                          className="userFormInput astroForm mb-3 py-1"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                    </div>
                    <div className="col-4">
                    <button
                        className="btn btn-danger"
                        type="submit"
                        name="action" >
                        Subscribe
                    </button>
                </div>
                </div>
                <div className="row formRow">
                <a href="/policy" className="text-center text-dark mb-3 homeowner link">* By clicking 'Subscribe' you agree to our terms and policies and to receive emails from Rosestone Roofing!</a>
            </div>
                <h3 className="text-center text-dark alertContent">{alert.message}</h3>
                

            </div>
           
        </form>
    </>
    );
};

export default SubForm;