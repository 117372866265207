import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import API from '../../utils/API';
import NavTabs from '../../utils/NavTabs';

const BlogPost = (props) => {
    const { id } = useParams();
    const [header, setHeader] = useState();
    const [text, setText] = useState();
    const [author, setAuthor] = useState();
    const [image, setImage] = useState();
    const [date, setDate] = useState();

    useEffect(() => {
      API.getBlog(id).then((response) => {
        setHeader(response.data.heading);
        setText(response.data.text);
        setAuthor(response.data.author);
        setImage(response.data.image);
        setDate(response.data.date);
      });
    })
    return (
        <>
       
       <button className="btn btn-light">
          <Link to='/blog'>Back</Link>
       </button>
                      <div className="text-center">
                        <img src={image} className="blogPost-img"/>
                      </div>
                      <div className="blogPost-text">
                        <h1 className="roof-header">{header}</h1>
                        <div className="row blog-text-row">
                     
                            <p className="">{text}</p>
                 
                       
                        </div>
                        <div className="row">
                      
                            <p className="blog-author">{author}</p>
                            <p className="blog-date">{date}</p>
                        
                         
                        </div>
                        </div>
                   
            </>       
    );
}

export default BlogPost;