import React, {useEffect, useState} from "react";
// import { Parallax, Background } from 'react-parallax';
import './about.css';
import NavTabs from "../../utils/NavTabs";
import API from "../../utils/API";

// const images = [
//   {
//     id: "001",
//     src: "../Images/gaf.png",
//     desc: "GAF",
//   },
//   {
//     id: "002",
//     src: "../Images/certainteed.png",
//     desc: "Certainteed",
//   },
//   {
//     id: "003",
//     src: "../Images/iko.jpg",
//     desc: "IKO",
//   },
// ];

const About = () => {
  const [loading, setLoading] = useState(true);
  const [exItems, setExItems] = useState([]);
  const [displayExItems, setDisplayExItems] = useState([]);
  let [currentPosition, setCurrentPosition] = useState(0); // Initial slide index value
  let currentSlide = displayExItems[currentPosition]; // variable index value we can reference later

  useEffect(() => {
    initializeMenu();
  }, []);

  const initializeMenu = () => {
    API.getExamples()
      .then((response) => {
        let items = [];
       for (let i=0; i < response.data.length; i++) {
         if(response.data[i].heading === 'logo') {
          items.push(response.data[i])
         }
       }
        setExItems(items);
        setDisplayExItems(items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Index value moves up, but doesn't update the image. Why???
  const arrowRightClick = () => {
    currentPosition !== displayExItems.length - 1 // Check index length
      ? setCurrentPosition(currentPosition + 1)
      : setCurrentPosition((currentPosition = 0));
    currentSlide = displayExItems[currentPosition];
  };

  const arrowLeftClick = () => {
    currentPosition !== 0 // Check index length
      ? setCurrentPosition(currentPosition - 1)
      : setCurrentPosition((currentPosition = displayExItems.length - 1));
    currentSlide = displayExItems[currentPosition];
  };

  if (loading) return null;
  if (!loading) return (
    <>
    <NavTabs/>
    <div className="about-page container mb-3 pb-3">
    <div className="row about-row">
              <div className="col-xl-6">
                <h1 className="roof-header">About Us</h1>
                <p className="about-text">At Rosestone Roofing we specialize in residential roofing in the Metro Atlanta area. Rosestone Roofing is fully Insured and licensed with the ability to create estimates on any roof. We offer a complimentary service to allow you to make an educated decision on the additions and repairs to your home. Rosestone has over 20 years of experience in the construction industry. The company's core values align with our motto “People over Profits”. We will stand by values such as loyalty,integrity,trust,honesty, and accountability.</p>
              </div>
              <div className="col-xl-6">
                {/* <img className="rosestone-landscape-img" src="../Images/rosestonelandscape.png" alt="example img"/> */}
                <div className="App">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d94779.28621089991!2d-84.42274626130208!3d33.83887037261815!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7a7fb03870a4dd6d!2sRoseStone%20Roofing!5e0!3m2!1sen!2sus!4v1664305010504!5m2!1sen!2sus"
        style={{ border: "0" }}
        className="rosestone-iframe-img"
        allowfullscreen=""
        loading="lazy"
        title="google map"
      ></iframe>
    </div>
              </div>
            </div>
    </div>
    {displayExItems[1] ?
     <div className="photos-container">
        <div className="row text-center">
        <h1 className="with-header">Companies we work with:</h1>
        </div>
        <div className="slide-row row" key={currentSlide._id}>
          <img className="gallery" alt="roof pics" src={currentSlide.image} />
        </div>
        <div className="">
        <div className=" arrows  text-center">
          <button className=" arrow-btn" onClick={arrowLeftClick}>
            {"<"}
          </button>
          <button className=" arrow-btn" onClick={arrowRightClick}>
            {">"}
          </button>
        </div>
        </div>
        <div className=" text-center currentSlide-text pt-3">
          <p className="">{currentSlide.text}</p>
        </div>
      </div>
     : 
     null
     }
   
    
    </>
  );
};

export default About;
