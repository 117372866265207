import React, {useEffect, useState} from "react";
import { Parallax, Background } from 'react-parallax';
import './home.css';
import NavTabs from "../../utils/NavTabs";
import Shingles from "../../components/Home/Shingles";
import SubForm from "../../components/Forms/SubForm";
import FreeForm from "../../components/Forms/FreeForm";
import { Link, useLocation } from "react-router-dom";
import API from "../../utils/API";

// const images = [
//   {
//     id: "001",
//     src: "../Images/gaf.png",
//     desc: "GAF",
//   },
//   {
//     id: "002",
//     src: "../Images/certainteed.png",
//     desc: "Certainteed",
//   },
//   {
//     id: "003",
//     src: "../Images/iko.jpg",
//     desc: "IKO",
//   },
// ];

const Home = () => {
  const [exItems, setExItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayExItems, setDisplayExItems] = useState([]);
  let [currentPosition, setCurrentPosition] = useState(0); // Initial slide index value
  let currentSlide = displayExItems[currentPosition]; // variable index value we can reference later

  useEffect(() => {
    initializeMenu();
  }, []);

  const initializeMenu = () => {
    API.getExamples()
      .then((response) => {
        let items = [];
        console.log(response)
       for (let i=0; i < response.data.length; i++) {
         if(response.data[i].heading === 'logo') {
          items.push(response.data[i])
         }
       }
        setExItems(items);
        setDisplayExItems(items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Index value moves up, but doesn't update the image. Why???
  const arrowRightClick = () => {
    currentPosition !== displayExItems.length - 1 // Check index length
      ? setCurrentPosition(currentPosition + 1)
      : setCurrentPosition((currentPosition = 0));
    currentSlide = displayExItems[currentPosition];
  };

  const arrowLeftClick = () => {
    currentPosition !== 0 // Check index length
      ? setCurrentPosition(currentPosition - 1)
      : setCurrentPosition((currentPosition = displayExItems.length - 1));
    currentSlide = displayExItems[currentPosition];
  };

  if (loading) return null;
  if (!loading) return (
    <>
    <NavTabs/>
    <div style={{overflowX: "clip"}}>
      <video src="../Images/newvid2.mp4" className="video" controls type="video/mp4"/>
    </div>
    <div classNme=" home-page">
      <Parallax strength={300} className="outerParallax">
          <Background className="parallax">
              <img src="../Images/rosestone2.jpg" className="rosestone1" alt="fill murray" />
          </Background>
          <div className="welcome-card">
            <div className="row">
              <h1 className="welcome-header">
                Welcome to Rosestone Roofing.
              </h1>
            </div>
            <div className="row">
              <ul className="shingles-list list-display list-checkmarks">
                <li className="shingles-text">
                At Rosestone Roofing we specialize in Residential Roofing projects in the Metro Atlanta area.
                </li>
                <li className="shingles-text">
                We are fully Insured and licensed with the ability to create estimates on any roof.
                </li>
                <li className="shingles-text">
                We provide financing options as well as other services to save the homeowner money. 
                </li>
                
              </ul>
            </div>
            <div className="row">
              <btn className="btn btn-light roof-btn">
              <Link to="/freeinspection" className="text-dark link">Request a FREE Inspection now!</Link>
              </btn>
            </div>
          </div>
      </Parallax>
    </div>
    <div className="inspection-row row my-3">
      
      <div className="col-sm-6">
        <div className="inspection-ul">
      <h1 className="inspection-header">
            What do we have to offer?
          </h1>
        <ul className="inspection-list list-display list-checkmarks">
          <li className="inspection-text">
          State of the art roofing system
          </li>
          <li className="inspection-text">
          Replace rotten decking
          </li>
          <li className="inspection-text">
          One day installment with same day clean-up
          </li>
          <li className="inspection-text">
          High quality materials
          </li>
          <li className="inspection-text">
          Complimentary inspection & quote
          </li>
          <li className="inspection-text">
          Third-Party Financing
          </li>
        </ul>
        </div>
      </div>
      <div className="col-sm-6">
      <div className="inspection-card">
            <div className="row">
              <h1 className="inspection-header-2">
                How do I get started?
              </h1>
            </div>
            <div className="row">
              <p className="inspection-text-2">
              During our installments we will tear off your leaky old roof and install a new state of the art roofing system. Within this branch we also provide siding,gutters,& window installation to our clients. Get started today with one CLICK below! 
              </p>
            </div>
            <div className="row">
            <btn className="btn btn-light roof-btn">
            <Link to="/freeinspection" className="text-dark link">Request a FREE Inspection now!</Link>
              </btn>
            </div>
          </div>
      </div>
    </div>
    {/* <div classNme=" roofing-system">
          <div className="roof-box">
          <div className="row about-inspection-top">
              <h1 className="roof-header text-center ">What do we look for in your Free Inspection?</h1>
              <p className="text-center">Inspections are conducted by trained and experienced Roofing Specialists only.</p>
              <hr></hr>
            </div>
            <div className="row about-inspection-bottom">
              <div className="col-sm-6 mt-3">
                  <ul className="roof-list list-display list-checkmarks">
                  <h3 className="roof-text mb-2 mt-3">
                  We Review:
                  </h3>
                  <li className="roof-text">
                  Roof Age
                  </li>
                  <li className="roof-text">
                  Storm Damage
                  </li>
                  <li className="roof-text">
                  Measurements
                  </li>
                  <li className="roof-text">
                  Steepness
                  </li>
                  <li className="roof-text">
                  Structure
                  </li>
                  <li className="roof-text">
                  Damage Caused From Leaks
                  </li>
                </ul>
              </div>
            <div className="col-sm-6 mb-3 mt-3">
              <img className="blog-img mt-3" src="../Images/rosestone12.jpg"></img>
            </div>
             
            </div>
            <div className="row">
              <h1 className="roof-header">The RoseStone Roofing System</h1>
              <hr></hr>
            </div>
            <div className="row mb-3">
            <ul className="roof-list list-display list-checkmarks">
                <li className="roof-text">
                Ridge vent-provides proper ventilation prolonging the life of the shingle.
                </li>
                <li className="roof-text">
                We lay Waterproof underlayment.
                </li>
                <li className="roof-text">
                Ice and water to serve as extra leak barrier around plumbing boots and vents.
                </li>
                <li className="roof-text">
                Drip edge helps protect the underlayment from strong winds and plywood from water damage.
                </li>
                <li className="roof-text">
                Flashing for chimneys, skylights, and dormers.
                </li>
                <li className="roof-text">
                We replace any rotten plywood with new OSB.
                </li>
                
              </ul>
             
            </div>
            <div className="row about-row">
              <div className="col-xl-6">
                <h1 className="roof-header">About Us</h1>
                <hr></hr>
                <p className="roof-text">At RoseStone Roofing we specialize Residential roofing in the Metro Atlanta area. RoseStone Roofing is fully Insured and licensed with the ability to create estimates on any roof. We prefer to work with your insurance in order to limit the cost to the homeowner (you!). Our teams have over 20+ years of experience taking down old roofs and putting up new ones! We also do siding, gutter, windows, sheds. </p>
              </div>
              <div className="col-xl-6">
                <img className="rosestone-landscape-img" src="../Images/rosestonelandscape.png"/>
              </div>
            </div>
          </div>
    </div> */}
    {/* <div className="inspection-row row">
      
      <div className="col-sm-6">
        <div className="inspection-ul">
      <h1 className="inspection-header">
            Need a new roof?
          </h1>
       
          
              <p className="inspection-text">
               We get it, getting a new roof isn't something you do everyday, so we did our best to simplify the process for you. Request a free inspection today, so we can work with your insurance agency to get you the lowest rate possible for your new roof. Not only will a new roof lengthen the longevity of your home, it will also add to the value of it as well.
              </p>
         
       
        </div>
      </div>
      <div className="col-sm-6">
      <div className="inspection-card">
            <div className="row">
              <h1 className="inspection-header-2">
                How do I get started?
              </h1>
            </div>
            <div className="row">
            <p className="inspection-text-2">
              With our installments we will tear the old roof off and install a new state of the art roofing system. We also do siding, gutter, windows, and sheds. This all starts with our free roof inspection.
              </p>
            </div>
            <div className="row">
            <btn className="btn btn-light roof-btn">
              <Link to="/freeinspection" className="text-dark link">Request a FREE Inspection now!</Link>
              </btn>
            </div>
          </div> 
      </div>
    </div> */}
   
   

    
    {/* <div className="work-with">
      <div className="row text-center">
        <h1 className="with-header">Companies we work with:</h1>
      </div>
      <div className="row companies-row">
        <div className="col-sm-4">
          <img className="company-img" src="../Images/iko.jpg"/>
        </div>
        <div className="col-sm-4">
          <img className="company-img" src="../Images/certainteed.png"/>
        </div>
        <div className="col-sm-4">
          <img className="company-img" src="../Images/gaf.png"/>
        </div>
      </div>
    </div> */}

{displayExItems[1] ?
     <div className="photos-container">
        <div className="row text-center">
        <h1 className="with-header">Companies we work with:</h1>
        </div>
        <div className="slide-row row" key={currentSlide._id}>
          <img className="gallery" alt="roof pics" src={currentSlide.image} />
        </div>
        <div className="">
        <div className=" arrows  text-center">
          <button className=" arrow-btn" onClick={arrowLeftClick}>
            {"<"}
          </button>
          <button className=" arrow-btn" onClick={arrowRightClick}>
            {">"}
          </button>
        </div>
        </div>
        <div className=" text-center currentSlide-text pt-3">
          <p className="">{currentSlide.text}</p>
        </div>
      </div>
     : 
     null
     }

    {/* <div className="photos-container">
      <div className="row text-center">
        <h1 className="roof-header">Past Projects:</h1>
      </div>
      <div className="video-row">
        <video className="video"  controls src="../Images/rosestonevideo2.mp4"/>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <img className="ex-photos" src="../Images/rosestone1.jpg" />
        </div>
        <div className="col-sm-4">
        <img className="ex-photos" src="../Images/rosestone2.jpg" />
        </div>
        <div className="col-sm-4">
        <img className="ex-photos" src="../Images/rosestone3.jpg" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <img className="ex-photos" src="../Images/rosestone4.jpg" />
        </div>
        <div className="col-sm-4">
        <img className="ex-photos" src="../Images/rosestone5.jpg" />
        </div>
        <div className="col-sm-4">
        <img className="ex-photos" src="../Images/rosestone6.jpg" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <img className="ex-photos" src="../Images/rosestone7.jpg" />
        </div>
        <div className="col-sm-4">
        <img className="ex-photos" src="../Images/rosestone8.jpg" />
        </div>
        <div className="col-sm-4">
        <img className="ex-photos" src="../Images/rosestone9.jpg" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <img className="ex-photos" src="../Images/rosestone10.jpg" />
        </div>
        <div className="col-sm-4">
        <img className="ex-photos" src="../Images/rosestone11.jpg" />
        </div>
        <div className="col-sm-4">
        <img className="ex-photos" src="../Images/rosestone12.jpg" />
        </div>
      </div>
    </div> */}

    {/* <div className="forms-row">
      <div className="row">
        <div className="col-md-6">
        <SubForm/>
        </div>
        <div className="col-md-6">
        <FreeForm/>
        </div>
      </div>
    </div> */}
    </>
  );
};


export default Home;
