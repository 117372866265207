import React, { useEffect, useState } from "react";
import { Parallax, Background } from "react-parallax";
import "./roofing.css";
import NavTabs from "../../utils/NavTabs";
import Shingles from "../../components/Home/Shingles";
import SubForm from "../../components/Forms/SubForm";
import FreeForm from "../../components/Forms/FreeForm";
import { Link, useLocation } from "react-router-dom";
import API from "../../utils/API";

// const images = [
//   {
//     id: "001",
//     src: "../Images/rosestone1.jpg",
//     desc: "Roof Pic",
//   },
//   {
//     id: "002",
//     src: "../Images/rosestone2.jpg",
//     desc: "Another Roof Pic ;)",
//   },
//   {
//     id: "003",
//     src: "../Images/rosestone3.jpg",
//     desc: "Roof Pic",
//   },
//   {
//     id: "004",
//     src: "../Images/rosestone4.jpg",
//     desc: "Another Roof Pic ;)",
//   },
//   {
//     id: "005",
//     src: "../Images/rosestone4.jpg",
//     desc: "Roof Pic",
//   },
//   {
//     id: "006",
//     src: "../Images/rosestone6.jpg",
//     desc: "Another Roof Pic ;)",
//   },
//   {
//     id: "007",
//     src: "../Images/rosestone7.jpg",
//     desc: "Roof Pic",
//   },
//   {
//     id: "008",
//     src: "../Images/rosestone8.jpg",
//     desc: "Another Roof Pic ;)",
//   },
//   {
//     id: "009",
//     src: "../Images/rosestone9.jpg",
//     desc: "Roof Pic",
//   },
//   {
//     id: "010",
//     src: "../Images/rosestone10.jpg",
//     desc: "Another Roof Pic ;)",
//   },
//   {
//     id: "011",
//     src: "../Images/rosestone11.jpg",
//     desc: "Roof Pic",
//   },
//   {
//     id: "012",
//     src: "../Images/rosestone12.jpg",
//     desc: "Another Roof Pic ;)",
//   },
// ];

const Residential = () => {
  const [loading, setLoading] = useState(true);
  const [exItems, setExItems] = useState([]);
  const [displayExItems, setDisplayExItems] = useState([]);
  let [currentPosition, setCurrentPosition] = useState(0); // Initial slide index value
  let currentSlide = displayExItems[currentPosition]; // variable index value we can reference later

  useEffect(() => {
    initializeMenu();
  }, []);

  const initializeMenu = () => {
    API.getExamples()
      .then((response) => {
        let items = [];
       for (let i=0; i < response.data.length; i++) {
         if(response.data[i].heading !== 'logo') {
          items.push(response.data[i])
         }
       }
        setExItems(items);
        setDisplayExItems(items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Index value moves up, but doesn't update the image. Why???
  const arrowRightClick = () => {
    currentPosition !== displayExItems.length - 1 // Check index length
      ? setCurrentPosition(currentPosition + 1)
      : setCurrentPosition((currentPosition = 0));
    currentSlide = displayExItems[currentPosition];
  };

  const arrowLeftClick = () => {
    currentPosition !== 0 // Check index length
      ? setCurrentPosition(currentPosition - 1)
      : setCurrentPosition((currentPosition = displayExItems.length - 1));
    currentSlide = displayExItems[currentPosition];
  };

  if (loading) return null;
  if (!loading) return (
    <>
      <NavTabs />
      <div className="container residential-page">
        <div className="row about-inspection-top">
          <h1 className="roof-header text-center ">
            What do we look for in your Free Inspection?
          </h1>
          <p className="text-center">
            Inspections are conducted by trained and experienced Roofing
            Specialists only.
          </p>
          <hr></hr>
        </div>
        <div className="row about-inspection-bottom">
          <div className="col-sm-6 mt-3">
            <ul className="roof-list list-display list-checkmarks">
              <h3 className="roof-text mb-2 mt-3">We Review:</h3>
              <li className="roof-text">Roof Age</li>
              <li className="roof-text">Storm Damage</li>
              <li className="roof-text">Ventilation System</li>
              <li className="roof-text">Measurements</li>
              <li className="roof-text">Steepness</li>
              <li className="roof-text">Structure</li>
              <li className="roof-text">Leaks</li>
            </ul>
          </div>
          <div className="col-sm-6 mb-3 mt-3">
            <img
              className="blog-img mt-3"
              src="../Images/rosestone12.jpg"
            ></img>
          </div>
        </div>
        <div className="row">
          <h1 className="roof-header">The RoseStone Roofing System</h1>
        </div>
        <div className="row">
          <ul className="roof-list list-display list-checkmarks">
            <li className="roof-text">
              Ridge vent-provides proper ventilation, prolonging the life of the
              shingle.
            </li>
            <li className="roof-text">We lay Waterproof underlayment.</li>
            <li className="roof-text">
              Ice and water protection serves as an extra leak barrier around
              plumbing boots,valleys,eaves,and vents.
            </li>
            <li className="roof-text">
              Drip edge helps protect the underlayment from strong winds and
              from water damage.
            </li>
            <li className="roof-text">
              Flashing for chimneys, skylights, and dormers.
            </li>
            <li className="roof-text">
              We replace any rotten plywood to brand new.
            </li>
            <li className="roof-text">
              We install 30 year Architectural shingles.
            </li>
          </ul>
        </div>
      </div>
      <div className="inspection-row row my-3">
        <div className="col-sm-6">
          <div className="inspection-ul">
            <h1 className="inspection-header">What do we have to offer?</h1>
            <ul className="inspection-list list-display list-checkmarks">
              <li className="inspection-text">State of the art roofing</li>
              <li className="inspection-text">Replace any rotten wood</li>
              <li className="inspection-text">Extra water protection</li>
              <li className="inspection-text">Replace plumbing boots</li>
              <li className="inspection-text">Full clean-up</li>
              <li className="inspection-text">
                We only use high quality materials
              </li>
            </ul>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="inspection-card">
            <div className="row">
              <h1 className="inspection-header-2">How do I get started?</h1>
            </div>
            <div className="row">
              <p className="inspection-text-2">
                With our installments we will tear the old roof off and install
                a new state of the art roofing system. We also do siding,
                gutter, windows, and sheds. This all starts with our free roof
                inspection.
              </p>
            </div>
            <div className="row">
              <btn className="btn btn-light roof-btn">
                <Link to="/freeinspection" className="text-dark">
                  Request a FREE Inspection now!
                </Link>
              </btn>
            </div>
          </div>
        </div>
      </div>

      <Shingles />

      <div className="inspection-row row mb-3 pb-3">
        <div className="col-sm-6">
          <div className="inspection-ul">
            <h1 className="inspection-header">Need a new roof?</h1>

            <p className="inspection-text">
              We understand a new roof is usually a first or a second time for
              homeowners. Getting a new roof isn't something you do everyday, so
              we did our best to simplify the process for you. Request a free
              inspection today, so we can work with you to assess your options
              to get you the lowest rate possible for your new roof without
              compromising quality. Not only will a new roof lengthen the
              longevity of your home, it will also add to the value of it as
              well.
            </p>
          </div>
        </div>
        <div className="col-sm-6">
          <img className="new-roof-img" src="../Images/newroof.png" />
        </div>
      </div>
      {displayExItems[1] ?
     <div className="photos-container">
        <div className="row text-center">
        <h1 className="with-header">Examples:</h1>
        </div>
        <div className="slide-row row" key={currentSlide._id}>
          <img className="gallery" alt="roof pics" src={currentSlide.image} />
        </div>
        <div className="">
        <div className=" arrows  text-center">
          <button className=" arrow-btn" onClick={arrowLeftClick}>
            {"<"}
          </button>
          <button className=" arrow-btn" onClick={arrowRightClick}>
            {">"}
          </button>
        </div>
        </div>
        <div className=" text-center currentSlide-text pt-3">
          <p className="">{currentSlide.text}</p>
        </div>
      </div>
     : 
     null
     }
      <div className="forms-row">
        <div className="row">
          <FreeForm />
        </div>
      </div>
    </>
  );
};

export default Residential;
