import React, { useState, useContext, useEffect } from 'react';
import axios from "axios"
import {useHistory} from "react-router-dom"
import AlertContext from '../../utils/alertContext';
import API from '../../utils/API';


const BlogForm = () => {
    const [heading, setHeading] = useState("");
    const [text, setText] = useState("");
    const [image, setImage] = useState("");
    const [author, setAuthor] = useState("");
    const history = useHistory()
    const alert = useContext(AlertContext);

    const [emails, setEmails] = useState([]);

    useEffect(()=>{
        loadResponses()
    }, [])

    function loadResponses() {
        API.getEmails()
          .then(res => 
            setEmails(res.data)
          )
          .catch(err => console.log(err));
      };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        axios
          .post("/api/blog/", { heading, text, image, author })
          .then((response) => {
            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
            alert.setAlert({
                message: "Please respond to each input!",
                type: "danger",
              });
          });
          axios
          .post("/mail/tosend/", { 'title': heading, 'message': text, image, author, 'emails': [emails.email] })
          .then((response) => {
            console.log(response.data);
            history.go(0);
          })
          .catch((err) => {
            console.log(err);
            alert.setAlert({
                message: "Please respond to each input!",
                type: "danger",
              });
          }); 
      };

    return (
    <>
     <form className="newUser" onSubmit={handleFormSubmit}>
        <div className="container admin-block">
            <div className="row formRow">
                <h1 className="text-center login-header mb-3">Post to the Blog</h1>
            </div>
            <div className="row mt-3 formRow">
                <div className="col-sm-3">
                    <label htmlFor="firstName" className="astroForm-label">Heading</label>
                </div>
                <div className="col-sm-9">
                    <input
                        id="heading"
                        type="text"
                        className="userFormInput astroForm mb-3"
                        value={heading}
                        onChange={(e) => {
                            setHeading(e.target.value);
                        }}
                    />
                    </div>
                </div>
                <div className="row formRow">
                    <div className="col-sm-3">
                        <label htmlFor="lastName" className="astroForm-label">Text</label>
                    </div>
                    <div className="col-sm-9">
                        <input
                          id="text"
                          type="text"
                          className="userFormInput astroForm mb-3"
                          value={text}
                          onChange={(e) => {
                            setText(e.target.value);
                          }}
                        />
                    </div>
                </div>
                <div className="row formRow">
                    <div className="col-sm-3">
                        <label htmlFor="email" className="astroForm-label">Image</label>
                    </div>
                    <div className="col-sm-9">
                        <input
                          id="image"
                          type="text"
                          className="userFormInput astroForm mb-3"
                          value={image}
                          onChange={(e) => {
                            setImage(e.target.value);
                          }}
                        />
                    </div>
                </div>
                <div className="row formRow">
                    <div className="col-sm-3">
                        <label htmlFor="password" className="astroForm-label">Author</label>
                    </div>
                    <div className="col-sm-9">
                        <input
                          id="author"
                          type="text"
                          className="userFormInput astroForm mb-3"
                          value={author}
                          onChange={(e) => {
                            setAuthor(e.target.value);
                          }}
                        />
                     </div>
                </div>
                <h3 className="text-center alertContent">{alert.message}</h3>
                <div className="row justify-content-center my-3 py-3">
                    <button
                        className="btn sub-btn"
                        type="submit"
                        name="action" >
                        Post
                    </button>
                </div>
            </div>
        </form>
    </>
    );
};

export default BlogForm;