import React from "react";
// import { Parallax, Background } from 'react-parallax';
import './contact.css';
import NavTabs from "../../utils/NavTabs";
import SubForm from "../../components/Forms/SubForm";
import FreeForm from "../../components/Forms/FreeForm";

const Contact = () => {
  return (
    <>
    <NavTabs/>
    <div className="forms-row">
      <div className="row">
        <div className="col-md-6">
        <FreeForm/>
        </div>
        <div className="col-md-6">
        <SubForm/>
        </div>
      </div>
    </div>
    </>
  );
};

export default Contact;
