import moment from 'moment';
import React, {useEffect, useState} from 'react';
import AdminNav from '../../utils/AdminNav';
import API from '../../utils/API';


function Dashboard() {

    const [responses, setResponses] = useState([])

    useEffect(()=>{
        loadResponses()
    }, [])



    function loadResponses() {
        API.getResponses()
          .then(res => {
            const arr = res.data
            const sortByDate = arr => {
              const sorter = (a, b) => {
                // console.log(moment(a.bookingDate, 'dddd, MMMM Do YYYY, hh:mm A').format())
                 return new Date(moment(a.bookingDate, 'dddd, MMMM Do YYYY, hh:mm A').format()).getTime() - new Date(moment(b.bookingDate, 'dddd, MMMM Do YYYY, hh:mm A').format()).getTime();
              }
              arr.sort(sorter);
           };
              sortByDate(arr)
              // console.log(arr)
              setResponses(arr)
          }
          )
          .catch(err => console.log(err));
      };

      function deleteResponse(id) {
        API.deleteResponse(id)
          .then(res => loadResponses())
          .catch(err => console.log(err));
      }

    return (
      <div className="adminResponse">
      <AdminNav/>
        <div className="row">
          <h1 className="pt-3 mt-3 text-center purple">RESPONSES</h1>
        </div>
        <div className=" responseTable scroll-roll">
        <table className="table scrollTable">
                <thead>
                    <tr>
                    <th scope="col">Requested Date</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th>
                    <th scope="col">Service</th>
                    <th scope="col">Roof Age</th>
                    <th scope="col">Via</th>
                    <th scope="col">Referral?</th>
                    <th scope="col">Booked On</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
            {responses.map(response => {
                  return (
                    <tr key={response._id}>
                      <td>{response.bookingDate}</td>
                        <td>{response.name}</td>
                        <td> 
                          <a href={"mailto:" + response.email}> {response.email} </a> 
                      </td>
                        <td>{response.phone}</td>
                        <td>{response.address}</td>
                        <td>{response.service}</td>
                        <td>{response.age}</td>
                        <td>
                          {response.find}
                        </td>
                        <td>{response.referral}</td>
                        <td>{response.date}</td>
                        
                        <td>
                          <button id="delete" className="btn btn-danger mb-3" onClick={() => deleteResponse(response._id)}>Delete</button>
                        </td> 
                    </tr>
                  );
                })}
                </tbody>
            </table>
        </div>
        </div>
    );
}

export default Dashboard;