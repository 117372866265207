import React, { useState, useContext, useEffect } from 'react';
import axios from "axios"
import {useHistory} from "react-router-dom"
import AlertContext from '../../utils/alertContext';
import API from '../../utils/API';


const ExampleForm = () => {
    const [heading, setHeading] = useState("");
    const [text, setText] = useState("");
    const [image, setImage] = useState("");
    const [author, setAuthor] = useState("");
    const history = useHistory()
    const alert = useContext(AlertContext);


    const handleFormSubmit = (e) => {
        e.preventDefault();
        axios
          .post("/api/example/", { heading, text, image })
          .then((response) => {
            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
            alert.setAlert({
                message: "Please respond to each input!",
                type: "danger",
              });
          });
         
      };

    return (
    <>
     <form className="newUser" onSubmit={handleFormSubmit}>
        <div className="container admin-block">
            <div className="row formRow">
                <h1 className="text-center login-header mb-3">Post Example</h1>
            </div>
            <div className="row mt-3 formRow">
                <div className="col-sm-3">
                    <label htmlFor="firstName" className="astroForm-label">Heading</label>
                </div>
                <div className="col-sm-9">
                    <input
                        id="heading"
                        type="text"
                        className="userFormInput astroForm mb-3"
                        value={heading}
                        onChange={(e) => {
                            setHeading(e.target.value);
                        }}
                    />
                    </div>
                </div>
                <h3 className="text-center alertContent">{"Use 'logo' for Heading if posting a Partners Logo"}</h3>
                <div className="row formRow">
                    <div className="col-sm-3">
                        <label htmlFor="lastName" className="astroForm-label">Text</label>
                    </div>
                    <div className="col-sm-9">
                        <input
                          id="text"
                          type="text"
                          className="userFormInput astroForm mb-3"
                          value={text}
                          onChange={(e) => {
                            setText(e.target.value);
                          }}
                        />
                    </div>
                </div>
                <div className="row formRow">
                    <div className="col-sm-3">
                        <label htmlFor="email" className="astroForm-label">Image URL</label>
                    </div>
                    <div className="col-sm-9">
                        <input
                          id="image"
                          type="text"
                          className="userFormInput astroForm mb-3"
                          value={image}
                          onChange={(e) => {
                            setImage(e.target.value);
                          }}
                        />
                    </div>
                </div>
                <h3 className="text-center alertContent">{alert.message}</h3>
                <div className="row justify-content-center my-3 py-3">
                    <button
                        className="btn sub-btn"
                        type="submit"
                        name="action" >
                        Post
                    </button>
                </div>
            </div>
        </form>
    </>
    );
};

export default ExampleForm;