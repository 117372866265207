import axios from "axios";

// eslint-disable-next-line
export default {
  // Gets all books
  getResponses: function () {
    return axios.get("/api/response");
  },
  // Gets the book with the given id
  getResponse: function (id) {
    return axios.get("/api/response/" + id);
  },
  // Deletes the book with the given id
  deleteResponse: function (id) {
    return axios.delete("/api/response/" + id);
  },
  // Saves a book to the database
  createResponse: function (responseData) {
    return axios.post("/api/response", responseData);
  },
  // Email List
  getEmails: function () {
    return axios.get("/api/email/");
  },
  // Blog posts
  getBlogs: function () {
    return axios.get("/api/blog/");
  },
  logoutAdmin: function (data) {
    return axios.post("/api/auth/logout", {}, { withCredentials: true });
  },

  // Returns blog
  getBlog: function (id) {
    return axios.get("/api/blog/" + id);
  },
  // Deletes one set of B4 and After pics
  deleteBlog: function (id) {
    return axios.delete("/api/blog/" + id);
  },
  // Creates B4 and After set
  createBlog: function (responseData) {
    return axios.post("/api/blog", responseData);
  },
  // Updates Set
  updateBlog: function (responseData, id) {
    return axios.put("/api/blog/" + id, responseData);
  },
  // Returns example
  getExample: function (id) {
    return axios.get("/api/example/" + id);
  },
  // Returns all examples
  getExamples: function (id) {
    return axios.get("/api/example");
  },
  // Deletes one set of B4 and After pics
  deleteExample: function (id) {
    return axios.delete("/api/example/" + id);
  },
  // Creates B4 and After set
  createExample: function (responseData) {
    return axios.post("/api/example", responseData);
  },
  // Updates Set
  updateExample: function (responseData, id) {
    return axios.put("/api/example/" + id, responseData);
  },
};
