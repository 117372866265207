import React, { useState, useContext } from 'react';
import axios from "axios"
import {useHistory} from "react-router-dom"
import AlertContext from '../../utils/alertContext';
import AdminNav from '../../utils/AdminNav'
import './admin.css';


const NewUser = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")

    const history = useHistory()
    const alert = useContext(AlertContext);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        axios
          .post("/api/auth/", { firstName, lastName, email, password })
          .then((response) => {
            console.log(response.data);
            history.push("/admin");
          })
          .catch((err) => {
            console.log(err);
            alert.setAlert({
                message: "Please respond to each input!",
                type: "danger",
              });
          }); 
      };

    return (
    <>
    <AdminNav/>
    <form className="newUser" onSubmit={handleFormSubmit}>
        <div className="container admin-block">
            <div className="row formRow">
                <h1 className="text-center login-header mb-3">Add Admin User</h1>
            </div>
            <div className="row mt-3 formRow">
                <div className="col-sm-3">
                    <label htmlFor="firstName" className="astroForm-label">First Name</label>
                </div>
                <div className="col-sm-9">
                    <input
                        id="first_name"
                        type="text"
                        className="userFormInput astroForm mb-3"
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                    />
                    </div>
                </div>
                <div className="row formRow">
                    <div className="col-sm-3">
                        <label htmlFor="lastName" className="astroForm-label">Last Name</label>
                    </div>
                    <div className="col-sm-9">
                        <input
                          id="last_name"
                          type="text"
                          className="userFormInput astroForm mb-3"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                    </div>
                </div>
                <div className="row formRow">
                    <div className="col-sm-3">
                        <label htmlFor="email" className="astroForm-label">Email</label>
                    </div>
                    <div className="col-sm-9">
                        <input
                          id="email"
                          type="email"
                          className="userFormInput astroForm mb-3"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                    </div>
                </div>
                <div className="row formRow">
                    <div className="col-sm-3">
                        <label htmlFor="password" className="astroForm-label">Password</label>
                    </div>
                    <div className="col-sm-9">
                        <input
                          id="password"
                          type="password"
                          className="userFormInput astroForm mb-3"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                     </div>
                </div>
                <h3 className="text-center alertContent">{alert.message}</h3>
                <div className="row justify-content-center my-3 py-3">
                    <button
                        className="btn sub-btn"
                        type="submit"
                        name="action" >
                        Create User
                    </button>
                </div>
            </div>
        </form>
    </>
    );
};

export default NewUser;