import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import AlertContext from "./utils/alertContext";
import TokenContext from "./utils/tokenContext";
import axios from "axios";
import jwt from "jsonwebtoken";
import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
import Contact from "./containers/Contact/Contact";
import About from "./containers/About/About";
import Blog from "./containers/Blog/Blog";
import Residential from "./containers/Roofing/Residential";
import InfoFoot from "./utils/InfoFoot";
import Dashboard from "./containers/Admin/Dashboard";
import NewUser from "./containers/Admin/NewUser";
import BlogManager from "./containers/Admin/BlogManager";
import ExampleManager from "./containers/Admin/ExampleManager";
import InspectionForm from "./containers/Contact/InspectionForm";
import BlogPost from "./containers/Blog/BlogPost";
import RemoveTime from "./containers/Admin/RemoveTime";

const App = () => {
    const [alert, setAlert] = useState({ message: "", type: "" });
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getJwt = async () => {
          try {
            const response = await axios.post("/api/auth/cookie");
            const token = response.data.token;
            jwt.verify(
              token,
              process.env.REACT_APP_JWT_SIGNATURE,
              (err, decoded) => {
                if (err) {
                  throw new Error(err);
                } else {
                  setToken(token);
                }
              }
            );
           
          } finally {
            setLoading(false);
          }
        };
        if (!token) {
          getJwt();
        }
      }, [token] );

      const login = (token, cb) => {
        setToken(token);
        cb();
      };
    
      const logout = (cb) => {
        setToken("");
        cb();
      }
    
      if (loading) return null;

    return (
        <Router>
            <AlertContext.Provider value={{ ...alert, setAlert: setAlert }}>
            <TokenContext.Provider value={{ token: token, logout: logout }}>
            <Route exact path="/" component={Home} />
            <Route exact path="/residential" component={Residential} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={About} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:id" component={BlogPost} />
            <Route exact path="/freeinspection" component={InspectionForm} />
            <ProtectedRoute exact path="/admin" component={Dashboard}/>
            <ProtectedRoute exact path="/removetime" component={RemoveTime}/>
            <ProtectedRoute exact path="/newuser" component={NewUser}/>
            <ProtectedRoute exact path="/blogmanager" component={BlogManager}/>
            <ProtectedRoute exact path="/examplemanager" component={ExampleManager}/>
            <Route exact path="/login" component={(props) => <Login {...props} login={login} />}/>
            <div className=" text-center footer-row">
                <InfoFoot/>
            </div>
            </TokenContext.Provider>
            </AlertContext.Provider>
        </Router>
      );
};

export default App;
