import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";

function NavTabs() {
  // We'll go into the Hooks API later, for now, we are just using some code
  // from the react-router docs (https://reacttraining.com/react-router/web/api/Hooks/uselocation)
  // This allows the component to check the route any time the user uses a link to navigate.
  const location = useLocation();

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img className="rosestone-logo" src="./Images/new-logo.PNG" />
          </a>
          <div style={{whiteSpace: "nowrap", display: "flex", overflow: "auto"}}>
          <a
            className="nav-link active hidden-big"
            aria-current="page"
            href="tel:404-803-4074"
          >
            <p className="nav-link m-0">
              <button
                className="btn btn-danger"
                style={{
                  background: "rgb(204, 0, 51)",
                  fontWeight: 19,
                  filter: "drop-shadow(1px 1px 1px rgb(179, 176, 176))",
                }}
              >
                CALL NOW
              </button>
            </p>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{height: "50px", marginTop: 11}}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <a className="nav-link active" aria-current="page">
                <Link
                  to="/"
                  className={
                    location.pathname === "/" ? "nav-link" : "nav-link"
                  }
                >
                  HOME
                </Link>
              </a>
              <a className="nav-link active" aria-current="page">
                <Link
                  to="/residential"
                  className={
                    location.pathname === "/residential"
                      ? "nav-link"
                      : "nav-link"
                  }
                >
                  RESIDENTIAL
                </Link>
              </a>
              <a className="nav-link active" aria-current="page">
                <Link
                  to="/blog"
                  className={
                    location.pathname === "/blog" ? "nav-link" : "nav-link"
                  }
                >
                  BLOG
                </Link>
              </a>
              <a className="nav-link active" aria-current="page">
                <Link
                  to="/contact"
                  className={
                    location.pathname === "/contact" ? "nav-link" : "nav-link"
                  }
                >
                  CONTACT
                </Link>
              </a>
              <a className="nav-link active" aria-current="page">
                <Link
                  to="/about"
                  className={
                    location.pathname === "/about" ? "nav-link" : "nav-link"
                  }
                >
                  ABOUT
                </Link>
              </a>
            </div>
          </div>
          <a
            className="nav-link active hidden-small"
            aria-current="page"
            href="tel:404-803-4074"
          >
            <p className="nav-link m-0">
              <button
                className="btn btn-danger"
                style={{
                  background: "rgb(204, 0, 51)",
                  fontWeight: 19,
                  filter: "drop-shadow(1px 1px 1px rgb(179, 176, 176))",
                }}
              >
                CALL NOW
              </button>
            </p>
          </a>
        </div>
      </nav>
      <div className="row inspection-link-row">
        <a
          className="inspection-link text-center"
          aria-current="page"
          href="/freeinspection"
        >
          <p className="nav-link m-0 text-white">
            Reserve your Free Inspection today!
          </p>
        </a>
      </div>
    </>
  );
}

export default NavTabs;
