import React from 'react';
import FreeForm from '../../components/Forms/FreeForm';
import NavTabs from '../../utils/NavTabs';

const InspectionForm = (props) => {
    return (
        <>
            <NavTabs/>
            <div className="inspection-page">
                <div className="row">
                    <FreeForm/>
                </div>
            </div>
        </>
    );
}

export default InspectionForm;