import React from 'react';

function InfoFoot(props) {
    return (
        <div className="container info-footer">
            <div className="row justify-content-center">
          <img
            src="/Images/new-logo.PNG"
            className="footerlogo my-3"
            alt="Logo"
          ></img>
        </div>
        <div className="row orange-foot">
          

          <div className="col-3">
            <a className="btn orange-foot btn-foot" href="/freeinspection">Free Inspection</a>
          </div>

          <div className="col-3">
          <a className="btn orange-foot btn-foot" href="/policy">Policies</a>
          </div>

          <div className="col-3">
          <a className="btn orange-foot btn-foot" href="/about">About</a>
          </div>

          <div className="col-3">
            <a className="btn orange-foot btn-foot" href="/login">Admin Login</a>
          </div>

          
        </div>
        <div className="row">
        <div className="col-4">
            <p className="text-light mb-0 pb-0 mt-3 pt-3">
            Rosestone Roofing LLC
            </p>
            <p className="text-light m-0 p-0">
            2236 Lawson Way 
            </p>
            <p className="text-light mt-0 pt-0 mb-3 pb-3">
            Chamblee, GA 30341
            </p>
          </div>
          <div className="col-4">
          <p className="text-light mb-0 pb-0 mt-3 pt-3">
              <strong>Other Services:</strong>
            </p>
            <p className="text-light p-0 m-0">
            <a className="" href="https://www.rosestonelandscaping.com/landscape/">Tree Service</a>
            </p>

            <p className="text-light p-0 m-0">
            <a className="" href="https://www.rosestonelandscaping.com/pool/">Swimming Pools</a>
            </p>
            
            <p className="text-light p-0 m-0">
            <a className="" href="https://www.rosestonelandscaping.com/hardscape/">Driveways</a>
            </p>

            <p className="text-light p-0 m-0 mb-3">
            <a className="" href="https://www.rosestonelandscaping.com/hardscape/">Hardscape</a>
            </p>
            
            
           
          </div>
          <div className="col-4">
            <p className="text-light mb-0 pb-0 mt-3 pt-3">
              Tel: (404) 670-9396
            </p>
            <p className="text-light m-0 p-0">
              Fax: (678) 620-1908
            </p>
            <p className="text-light mt-0 pt-0 mb-3 pb-3">
              team@rosestone.com
            </p>
          </div>
        </div>
       
        <div className="row text-center text-light">
          <p className="copyrightIcon">© ASTRONET DIGITAL 2021</p>
        </div>
        </div>
    );
}

export default InfoFoot;