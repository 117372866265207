import React, {useEffect, useState} from 'react';
import BlogForm from '../../components/Forms/BlogForm';
import AdminNav from '../../utils/AdminNav';
import API from '../../utils/API';
import MyExamples from './Update/MyExamples';

function BlogManager(props) {

    const [responses, setResponses] = useState([])

    useEffect(()=>{
        loadResponses()
    }, [])

    function loadResponses() {
        API.getEmails()
          .then(res => 
            setResponses(res.data)
          )
          .catch(err => console.log(err));
      };

      function deleteResponse(id) {
        API.deleteResponse(id)
          .then(res => loadResponses())
          .catch(err => console.log(err));
      }

    return (
        <>
           <AdminNav/>
           <div className="container">
               <div className="row blog-post">
                   <BlogForm/>
               </div>
               <div className="row blog-post">
                   <MyExamples/>
               </div>
           </div>
           <div className="row">
          <h1 className="pt-3 mt-3 text-center purple">Subscription List:</h1>
        </div>
        <div className="responseTable">
        <table className="table scrollTable">
                <thead>
                    <tr>           
                    <th scope="col">Email</th>
                    <th scope="col">Date</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
            {responses.map(response => {
                  return (
                    <tr key={response._id}>
                        
                        <td> 
                          <a href={"mailto:" + response.email}> {response.email} </a> 
                      </td>
                        
                        <td>{response.date}</td>
                        <td>
                          <button id="delete" className="btn btn-danger mb-3" onClick={() => deleteResponse(response._id)}>Delete</button>
                        </td> 
                    </tr>
                  );
                })}
                </tbody>
            </table>
        </div>
        </>
    );
}

export default BlogManager;