import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import AlertContext from "../../utils/alertContext";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import "../ClickedDate/Click.css";
import "../Schedule/Schedule.css";
import displayToast from "../../utils/toast/toast";

const FreeForm = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [service, setService] = useState("");
  const [age, setAge] = useState("");
  const [find, setFind] = useState("");
  const [referral, setReferral] = useState("");
  const [theDate, setTheDate] = useState("");
  const [booking, setBooking] = useState({});

  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);
  const [stepFive, setStepFive] = useState(false);
  const [stepSix, setStepSix] = useState(false);

  const history = useHistory();
  const alert = useContext(AlertContext);
  const [value, onChange] = useState(new Date());

  // sched
  const [date, setDate] = useState();
  const [beforeDate, setBeforeDate] = useState();
  const [nextDate, setNextDate] = useState();
  const [beforeUrl, setBeforeUrl] = useState();
  const [nextUrl, setNextUrl] = useState();
  const [skipSundayBefore, setSkipSundayBefore] = useState();
  const [skipSundayNext, setSkipSundayNext] = useState();

  const timeSlots = ["9:00 AM","11:00 AM", "1:00 PM", "3:00 PM"];
  const rows = [];
  const [newTimeSlots, setNewTimeSlots] = useState([]);

  useEffect(() => {
    if (!request) {
         window.scrollTo(0, 0);
    }
  });

  const requestService = (e) => {
    e.preventDefault();
    setLoading(true);
    setRequest(true);
    setLoading(false);
  };

  const goToStepTwo = (e) => {
    e.preventDefault();
    if (service === "") {
      displayToast("Please select a service", "red")
    } else {
    setLoading(true);
    setRequest(true);
    setStepOne(true);
    setLoading(false);
    };
  };

  const goToStepThree = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || phone ==="" || address === "") {
      displayToast("Please fill all inputs", "red")
    } else {
    setLoading(true);
    setRequest(true);
    setStepOne(false);
    setStepTwo(true);
    setLoading(false);
    };
  };

  const goToStepFour = (e) => {
    e.preventDefault();
    if (age === "") {
      displayToast("Please enter roof age", "red")
    } else {
    setLoading(true);
    setRequest(true);
    setStepOne(false);
    setStepTwo(false);
    setStepThree(true);
    setLoading(false);
    };
  };

  const goToStepFive = (e) => {
    e.preventDefault();
    if (find === "") {
      displayToast("Please fill all inputs", "red")
    } else {
    setLoading(true);
    setRequest(true);
    setStepOne(false);
    setStepTwo(false);
    setStepThree(false);
    setStepFour(true);
    setLoading(false);
    };
  };

    const onClickDay = (value, event) => {
      // event.preventDefault();
      setLoading(true);
        console.log(value);
        const thisValue = value.toString();
        const string = thisValue.split(' ');
        let month;
        if (string[1] === 'Jan') {
            month = "01";
        } else if (string[1] === 'Feb') {
            month = "02";
        } else if (string[1] === 'Mar') {
            month = "03";
        } else if (string[1] === 'Apr') {
            month = "04";
        } else if (string[1] === 'May') {
            month = "05";
        } else if (string[1] === 'Jun') {
            month = "06";
        } else if (string[1] === 'Jul') {
            month = "07";
        } else if (string[1] === 'Aug') {
            month = "08";
        } else if (string[1] === 'Sep') {
            month = "09";
        } else if (string[1] === 'Oct') {
            month = "10";
        } else if (string[1] === 'Nov') {
            month = "11";
        } else if (string[1] === 'Dec') {
            month = "12";
        }
        const clickedDate = `${string[0]} ${month} ${string[2]} ${string[3]}`;
        console.log(`Clicked day: ${clickedDate}`);
        setTheDate(clickedDate)
        setDate(moment(clickedDate, 'MM DD YYYY').format('dddd, MMMM Do YYYY'));
    setBeforeDate(moment(clickedDate, 'MM DD YYYY').add(-1, 'days').format('MMMM D'));
    setNextDate(moment(clickedDate, 'MM DD YYYY').add(1, 'days').format('MMMM D'));
    setBeforeUrl(moment(clickedDate, 'MM DD YYYY').add(-1, 'days').format('ddd MM DD YYYY'));
    setNextUrl(moment(clickedDate, 'MM DD YYYY').add(1, 'days').format('ddd MM DD YYYY'));
    setSkipSundayBefore(moment(clickedDate, 'MM DD YYYY').add(-2, 'days').format('ddd MM DD YYYY'));
    setSkipSundayNext(moment(clickedDate, 'MM DD YYYY').add(2, 'days').format('ddd MM DD YYYY'));
    const checkAppointments = async () => {
        try {
             for (let i = 0; i < timeSlots.length; i++) {
                 await axios.get('/api/response', {
                    params: {
                    bookingDate: `${moment(clickedDate, 'MM DD YYYY').format('dddd, MMMM Do YYYY')}, ${timeSlots[i]}`
                    }
                })
                .then(res => {
                    if (res.data.length === 0) {
                        rows.push(timeSlots[i]);
                    } else {
                        console.log(timeSlots[i]);
                    }
                })
                .catch(err => rows.push(timeSlots[i]));
            }
        } finally {
            if (rows.length === 0) {
                alert.setAlert({
                    message: "NO AVAILABLE APPOINTMENTS",
                    type: "danger",
                  });
            } else {
                alert.setAlert({
                    message: "",
                    type: "danger",
                  });
            }
            setNewTimeSlots(rows);
            setLoading(false);
        };
     };
         checkAppointments();
        setRequest(true);
        setStepOne(false);
        setStepTwo(false);
        setStepThree(false);
        setStepFour(false);
        setStepFive(true);
        setLoading(false);
    };
    

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const date = booking.date;
    const time = booking.time;
    const newDate = date + ', ' + time;
    axios
      .post("/api/response/", {
        name,
        email,
        phone,
        address,
        service,
        age,
        find,
        referral,
        bookingDate: newDate,
      })
      .then((response) => {
        console.log(response.data);
        history.push("/about");
        axios
          .post("/mail/tosend/", {
            title: `Response from ${name}`,
            message: `email: ${email}, phone: ${phone}, address: ${address}, requested service: ${service}, roof age: ${age}, date: ${booking.time + ", " + booking.date}, found us via ${find} ${referral}`,
            emails: ["rosestoneroofing@gmail.com"],
          })
          .then((response) => {
            console.log(response.data);
            history.go(0);
          })
          .catch((err) => {
            console.log(err);
            alert.setAlert({
              message: "Please respond to each input!",
              type: "danger",
            });
          })
          .catch((err) => {
            console.log(err);
            alert.setAlert({
              message: "Please respond to each input!",
              type: "danger",
            });
          });
      });
  };

  const BeforeDate = (event) => {
    const split = beforeUrl.split(" ");
    let time;
    if (split[0] === "Sun") {
        console.log(skipSundayBefore);
        setDate(moment(skipSundayBefore, 'MM DD YYYY').format('dddd, MMMM Do YYYY'));
        time = moment(skipSundayBefore, 'MM DD YYYY').format('ddd MMM D YYYY');
    } else {
      setDate(moment(beforeUrl, 'MM DD YYYY').format('dddd, MMMM Do YYYY'));
      time = moment(beforeUrl, 'MM DD YYYY').format('ddd MMM D YYYY');
    }
    onClickDay(time)
};

const NextDate = (event) => {
    const split = nextUrl.split(" ");
    let time;
    if (split[0] === "Sun") {
        console.log(skipSundayNext);
        setDate(moment(skipSundayNext, 'MM DD YYYY').format('dddd, MMMM Do YYYY'));
        time = moment(skipSundayNext, 'MM DD YYYY').format('ddd MMM D YYYY');
    } else {
      setDate(moment(nextUrl, 'MM DD YYYY').format('dddd, MMMM Do YYYY'));
      time = moment(nextUrl, 'MM DD YYYY').format('ddd MMM D YYYY');
    }
    onClickDay(time)
};

const handleClick = async (event) => {
  const { value } = event.target;
  const appointmentBooking = {
    date: date,
    time: value,
  }
  setBooking(appointmentBooking)
  console.log(appointmentBooking)
  setRequest(true);
  setStepOne(false);
  setStepTwo(false);
  setStepThree(false);
  setStepFour(false);
  setStepFive(false);
  setStepSix(true);
  setLoading(false);
  } 



  if (loading) return null;

  if (stepSix) 
  return (
    <>
 <div className="newUser container">
          <div className="container free-form text-light">
          <div className="row formRow">
              <h5 className="text-center login-header mt-3 text-dark mb-3">
                Free Inspection
              </h5>
            </div>
            <div className="row formRow">
              <p className="text-center mt-3 text-dark mb-3">
               {`Thank you for considering us, ${name}`}
              </p>
            </div>
            <div className="row formRow">
              <p className="text-center mt-3 text-dark mb-3">
               {`Service Requested: ${service}`}
              </p>
            </div>
            <div className="row formRow">
              <p className="text-center mt-3 text-dark mb-3">
               {`Address: ${address}`}
              </p>
            </div>
            <div className="row formRow">
              <p className="text-center mt-3 text-dark mb-3">
               {`Date: ${booking.time + ", " + booking.date}`}
              </p>
            </div>
            <div className="row text-center my-3 ">
              <button
                className="btn sub-btn sub-btn-danger"
                type="click"
                onClick={(e) => handleFormSubmit(e)}
                name="action"
              >
                Submit
              </button>
            </div>
          </div>
          </div>
    </>
  )

  if (stepFive) 
    return (
      <>
 
    
 <>
 <div className="newUser container">
          <div className="container free-form text-light">
      
      <div className="container timeContainer">
          <div className="row text-center">
              <div className="col-3">
                  <button className="btn btn-date" onClick={() => BeforeDate()}>
                      {beforeDate}
                  </button>
              </div>
              <div className="col-6">
                  <h1 className="dateTitle">{date}</h1>
              </div>
              <div className="col-3">
                  <button className="btn btn-date" onClick={() => NextDate()}>
                          {nextDate}
                  </button>
              </div>
          </div>
          <div className="row text-center">
              <p className="availableDates">AVAILABLE TIMES:</p>
          </div>
          <div className="row">
              <ul className="timeSlots">
              <h5 className="noAppointments">{alert.message}</h5>
              {newTimeSlots.map(response => {
                  if (loading) return <h1>loading...</h1>;
                  if (!loading) return (
                      <li><button type="btn" className="hourBtn before" key={response} value={response} onClick={(e) => handleClick(e)}>{response}</button></li>
                  );
              })}
              </ul>
          </div>
      </div> 
      </div>
      </div>
      </>
 

      </>
    )

  if (stepFour) 
    return (
      <>
   <div className="newUser container">
          <div className="container free-form text-light">
          <div className="row formRow">
              <h5 className="text-center login-header mt-3 text-dark mb-3">
                Please choose a date for your free inspection
              </h5>
            </div>
          <div className="row calendarBox">
         <Calendar
            className="calendar tileDisabled"
            onChange={onChange}
            onClickDay={onClickDay}
            value={value}
            minDate= {new Date()}
            tileDisabled={({activeStartDate, date, view }) => date.getDay() === 0}
         />
    </div>
          </div>
    </div>
  

  
  


      </>
    )

  if (stepThree)
    return (
      <>
        <form className="newUser">
          <div className="container  free-form-2 text-light">
            <div className="row formRow">
              <h5 className="text-center login-header mt-3 text-dark mb-3">
                How did you hear about Rosestone?
              </h5>
            </div>

            {find === "Referral" ? (
              <div className="row formRow mt-3 pt-3">
                     <div className="col-sm-3">
                  <label
                    htmlFor="referral"
                    className="astroForm-label text-dark"
                  >
                    Referrer
                  </label>
                </div>
                <div className="col-sm-9">
                  <input
                    id="referral"
                    type="text"
                    className="userFormInput astroForm mb-3"
                    value={referral}
                    onChange={(e) => {
                      setReferral(e.target.value);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="row formRow">
                <div className="col-12 mt-3">
                  <select
                    id="phone"
                    type="text"
                    className="userFormInput astroForm mobile-form m-3"
                    value={find}
                    onChange={(e) => {
                      setFind(e.target.value);
                    }}
                  >
                    <option></option>
                    <option value="BBB">BBB</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Referral">Referral</option>
                    <option value="Door Hanger">Door Hanger</option>
                    <option value="Direct Mail">Direct Mail</option>
                    <option value="Yard Sign">Yard Sign</option>
                  </select>
                </div>
              </div>
            )}
            <div className="row text-center my-3 ">
              <button
                className="btn sub-btn sub-btn-danger"
                type="click"
                onClick={(e) => goToStepFive(e)}
                name="action"
              >
                Book
              </button>
            </div>
          </div>
        </form>
      </>
    );

  if (stepTwo)
    return (
      <>
        <form className="newUser">
          <div className="container  free-form-2 text-light">
            <div className="row formRow">
              <h5 className="text-center login-header mt-3 text-dark mb-3">
                How old is your current Roof?
              </h5>
            </div>
            <div className="row formRow">
              <div className="col-12 mt-3">
                <input
                  id="age"
                  type="number"
                  className="userFormInput astroForm m-3"
                  value={age}
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row text-center my-3 ">
              <button
                className="btn sub-btn sub-btn-danger"
                type="click"
                onClick={(e) => goToStepFour(e)}
                name="action"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </>
    );

  if (stepOne)
    return (
      <>
        <form className="newUser">
          <div className="container  free-form-2 text-light">
            <div className="row formRow">
              <h5 className="text-center mt-3 login-header text-dark mb-3">
                Contact Info
              </h5>

              <div className="row formRow">
                <div className="col-3">
                  <label
                    htmlFor="lastName"
                    className=" text-dark"
                  >
                    Name
                  </label>
                </div>
                <div className="col-9">
                  <input
                    id="name"
                    type="name"
                    className="userFormInput astroForm mb-3"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row formRow">
                <div className="col-3">
                  <label
                    htmlFor="lastName"
                    className=" text-dark"
                  >
                    Email
                  </label>
                </div>
                <div className="col-9">
                  <input
                    id="email"
                    type="email"
                    className="userFormInput astroForm mb-3"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row formRow">
                <div className="col-3">
                  <label
                    htmlFor="lastName"
                    className=" text-dark"
                  >
                    Phone
                  </label>
                </div>
                <div className="col-9">
                  <input
                    id="phone"
                    type="text"
                    className="userFormInput astroForm mb-3"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row formRow">
                <div className="col-3">
                  <label
                    htmlFor="address"
                    className=" text-dark"
                  >
                    Address
                  </label>
                </div>
                <div className="col-sm-9">
                  <input
                    id="address"
                    type="text"
                    className="userFormInput astroForm mb-3"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row text-center my-3 ">
              <button
                className="btn sub-btn sub-btn-danger"
                type="click"
                onClick={(e) => goToStepThree(e)}
                name="action"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </>
    );

  if (request)
    return (
      <>
        <form className="newUser">
          <div className="container  free-form-2 text-light">
            <div className="row formRow">
              <h5 className="text-center  mt-3 login-header text-dark mb-3">
                Select a Service
              </h5>
            </div>
            <div className="row formRow">
              <div className="col-12 mt-3">
                <select
                  id="phone"
                  type="text"
                  className="userFormInput astroForm mobile-form m-3"
                  value={service}
                  onChange={(e) => {
                    setService(e.target.value);
                  }}
                >
                  <option></option>
                  <option value="Replace/Install New Roof">
                    Replace/Install New Roof
                  </option>
                  <option value="Repair">Repair</option>
                  <option value="Storm/Hail Damage Analysis">
                    Storm/Hail Damage Analysis
                  </option>
                  <option value="Insurance Claim Assistance">
                    Insurance Claim Assistance
                  </option>
                  <option value="Other Services">Other Services</option>
                </select>
              </div>
            </div>

            <div className="row text-center my-3 ">
              <button
                className="btn sub-btn sub-btn-danger"
                type="click"
                onClick={(e) => goToStepTwo(e)}
                name="action"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </>
    );

  if (!request)
    return (
      <>
        <form className="newUser">
          <div className="container  free-form text-light">
            <div className="row formRow">
              <h1 className="text-center login-header text-dark mb-3 mt-3">
                Click Below
              </h1>
            </div>
            <div className="row formRow">
              <h5 className="text-center  text-dark mb-3">
                Contact us today to receive a call back within 24 hours!
              </h5>
            </div>
            <div className="row text-center my-3 ">
              <button
                className="btn sub-btn sub-btn-danger"
                type="click"
                onClick={(e) => requestService(e)}
                name="action"
              >
                Request
              </button>
              <a href="/policy" className="homeowner link text-dark mt-3">
                *By requesting a free inspection you agree to our terms and
                policies and to receive follow up emails from Rosestone Roofing
              </a>
            </div>
          </div>
        </form>
      </>
    );
};

export default FreeForm;
