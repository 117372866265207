import React from 'react';
import {Parallax, Background} from 'react-parallax';

const Shingles = (props) => {
    return (
        <>
             <div classNme=" shingles-system">
      <Parallax strength={300} className="shingles-outer">
          <Background className="parallax">
              <img src="../Images/rosestone1.jpg" className="roof-img" alt="fill murray" />
          </Background>
          <div className="shingles-box">
            <div className="row">
              <h1 className="shingles-header">Our Shingles:</h1>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="shingles-red-box">
              <p className="shingles-sub">
                3-Tab Shingles:
                </p>
              <ul className="roof-list list-display list-checkmarks">
                
                <li className="shingles-text">
                20 year warranty
                </li>
                <li className="shingles-text">
                60 mph winds
                </li>
                <li className="shingles-text">
                Installation times are higher than Architectural Shingles
                </li>
              </ul>
              </div>
              </div>
              <div className="col-md-4">
                <div className="shingles-black-box">
              <p className="shingles-sub">
                Architectural Shingles:
                </p>
              <ul className="shingles-list list-display list-checkmarks">
                <li className="shingles-text">
                30 year warranty/Lifetime warranty
                </li>
                <li className="shingles-text">
                130 mph winds
                </li>
                <li className="shingles-text">
                Class 4 Impact resistance
                </li>
                <li className="shingles-text">
                10-50 Year algae resistance
                </li>
                <li className="shingles-text">
                Advanced Color-blending technology
                </li>
              </ul>
              </div>
              </div>
              <div className="col-md-4">
                <div className="shingles-red-box">
              <p className="shingles-sub">
                Designer Shingles:
                </p>
              <ul className="roof-list list-display list-checkmarks">
                
                <li className="shingles-text">
                30 year lifetime
                </li>
                <li className="shingles-text">
                130mph wind resistance
                </li>
                <li className="shingles-text">
                High definition color blending
                </li>
                <li className="shingles-text">
                10-50 Year algae resistance
                </li>
                <li className="shingles-text">
                Impact resistant
                </li>
                <li className="shingles-text">
                Fire resistant
                </li>
              </ul>
              </div>
              </div>
              </div>
          </div>
      </Parallax>
   </div>
        </>
    );
}

export default Shingles;