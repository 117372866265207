import React, {useContext} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import API from "./API";
import './navbar.css';
import TokenContext from "./tokenContext";

function AdminNav() {
    // We'll go into the Hooks API later, for now, we are just using some code
    // from the react-router docs (https://reacttraining.com/react-router/web/api/Hooks/uselocation)
    // This allows the component to check the route any time the user uses a link to navigate.
    const location = useLocation();
    const {logout, token} = useContext(TokenContext);
    const history = useHistory();

    const logOutUser = () => {
      logout(() => {
        history.push("/");
        API.logoutAdmin();
      })
    }

    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img className="rosestone-logo" src="./Images/new-logo.PNG"/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav">
                <a className="nav-link active" aria-current="page">
                        <Link
                            to="/admin"
                            className={
                                location.pathname === "/admin"
                                ? "nav-link"
                                : "nav-link"
                            }
                            >
                           Responses
                        </Link>
                    </a>
                    <a className="nav-link active" aria-current="page">
                        <Link
                            to="/blogmanager"
                            className={
                                location.pathname === "/blogmanager"
                                ? "nav-link"
                                : "nav-link"
                            }
                            >
                            Blog Manager
                        </Link>
                    </a>

                    <a className="nav-link active" aria-current="page">
                        <Link
                            to="/removetime"
                            className={
                                location.pathname === "/removetime"
                                ? "nav-link"
                                : "nav-link"
                            }
                            >
                            Remove Time-Slot
                        </Link>
                    </a>

                    <a className="nav-link active" aria-current="page">
                        <Link
                            to="/examplemanager"
                            className={
                                location.pathname === "/examplemanager"
                                ? "nav-link"
                                : "nav-link"
                            }
                            >
                            Examples
                        </Link>
                    </a>
                    
                    <a className="nav-link active" aria-current="page">
                        <Link
                            to="/newuser"
                            className={
                                location.pathname === "/newuser"
                                ? "nav-link"
                                : "nav-link"
                            }
                            >
                            New User
                        </Link>
                    </a>
                    <button className="btn btn-dark logoutBtn " onClick={()=>logOutUser()}>Logout</button>
                </div>
                </div>
            </div>
        </nav>
        </>
    );
}

export default AdminNav;