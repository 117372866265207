import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { useHistory } from "react-router-dom";
import AdminNav from "../../utils/AdminNav";
import AlertContext from "../../utils/alertContext";
import displayToast from "../../utils/toast/toast";

const RemoveTime = (props) => {
  const [theDate, setTheDate] = useState("");
  const [booking, setBooking] = useState({});

  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [stepFive, setStepFive] = useState(false);
  const [stepSix, setStepSix] = useState(false);

  const history = useHistory();
  const alert = useContext(AlertContext);
  const [value, onChange] = useState(new Date());

  // sched
  const [date, setDate] = useState();
  const [beforeDate, setBeforeDate] = useState();
  const [nextDate, setNextDate] = useState();
  const [beforeUrl, setBeforeUrl] = useState();
  const [nextUrl, setNextUrl] = useState();
  const [skipSundayBefore, setSkipSundayBefore] = useState();
  const [skipSundayNext, setSkipSundayNext] = useState();

  const timeSlots = ["9:00 AM", "11:00 AM", "1:00 PM", "3:00 PM"];
  const rows = [];
  const [newTimeSlots, setNewTimeSlots] = useState([]);

  useEffect(() => {
    if (!request) {
      window.scrollTo(0, 0);
    }
  });

  const onClickDay = (value, event) => {
    // event.preventDefault();
    setLoading(true);
    console.log(value);
    const thisValue = value.toString();
    const string = thisValue.split(" ");
    let month;
    if (string[1] === "Jan") {
      month = "01";
    } else if (string[1] === "Feb") {
      month = "02";
    } else if (string[1] === "Mar") {
      month = "03";
    } else if (string[1] === "Apr") {
      month = "04";
    } else if (string[1] === "May") {
      month = "05";
    } else if (string[1] === "Jun") {
      month = "06";
    } else if (string[1] === "Jul") {
      month = "07";
    } else if (string[1] === "Aug") {
      month = "08";
    } else if (string[1] === "Sep") {
      month = "09";
    } else if (string[1] === "Oct") {
      month = "10";
    } else if (string[1] === "Nov") {
      month = "11";
    } else if (string[1] === "Dec") {
      month = "12";
    }
    const clickedDate = `${string[0]} ${month} ${string[2]} ${string[3]}`;
    console.log(`Clicked day: ${clickedDate}`);
    setTheDate(clickedDate);
    setDate(moment(clickedDate, "MM DD YYYY").format("dddd, MMMM Do YYYY"));
    setBeforeDate(
      moment(clickedDate, "MM DD YYYY").add(-1, "days").format("MMMM D")
    );
    setNextDate(
      moment(clickedDate, "MM DD YYYY").add(1, "days").format("MMMM D")
    );
    setBeforeUrl(
      moment(clickedDate, "MM DD YYYY").add(-1, "days").format("ddd MM DD YYYY")
    );
    setNextUrl(
      moment(clickedDate, "MM DD YYYY").add(1, "days").format("ddd MM DD YYYY")
    );
    setSkipSundayBefore(
      moment(clickedDate, "MM DD YYYY").add(-2, "days").format("ddd MM DD YYYY")
    );
    setSkipSundayNext(
      moment(clickedDate, "MM DD YYYY").add(2, "days").format("ddd MM DD YYYY")
    );
    const checkAppointments = async () => {
      try {
        for (let i = 0; i < timeSlots.length; i++) {
          await axios
            .get("/api/response", {
              params: {
                bookingDate: `${moment(clickedDate, "MM DD YYYY").format(
                  "dddd, MMMM Do YYYY"
                )}, ${timeSlots[i]}`,
              },
            })
            .then((res) => {
              if (res.data.length === 0) {
                rows.push(timeSlots[i]);
              } else {
                console.log(timeSlots[i]);
              }
            })
            .catch((err) => rows.push(timeSlots[i]));
        }
      } finally {
        if (rows.length === 0) {
          alert.setAlert({
            message: "NO AVAILABLE APPOINTMENTS",
            type: "danger",
          });
        } else {
          alert.setAlert({
            message: "",
            type: "danger",
          });
        }
        setNewTimeSlots(rows);
        setLoading(false);
      }
    };
    checkAppointments();
    setRequest(true);
    setStepFive(true);
    setLoading(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const date = booking.date;
    const time = booking.time;
    const newDate = date + ", " + time;
    axios
      .post("/api/response/", {
        name: "HIDDEN SLOT",
        email: "team@rosestone.com",
        phone: "0000000000",
        address: "DELETE TO ADD SLOT BACK",
        service: "REMOVED-TIME",
        age: 1,
        find: "REMOVED-TIME",
        referral: "",
        bookingDate: newDate,
      })
      .then((response) => {
        console.log(response.data);
        history.push("/admin");
      })
      .catch((err) => {
        console.log(err);
        alert.setAlert({
          message: "Please respond to each input!",
          type: "danger",
        });
      });
  };

  const BeforeDate = (event) => {
    const split = beforeUrl.split(" ");
    let time;
    if (split[0] === "Sun") {
      console.log(skipSundayBefore);
      setDate(
        moment(skipSundayBefore, "MM DD YYYY").format("dddd, MMMM Do YYYY")
      );
      time = moment(skipSundayBefore, "MM DD YYYY").format("ddd MMM D YYYY");
    } else {
      setDate(moment(beforeUrl, "MM DD YYYY").format("dddd, MMMM Do YYYY"));
      time = moment(beforeUrl, "MM DD YYYY").format("ddd MMM D YYYY");
    }
    onClickDay(time);
  };

  const NextDate = (event) => {
    const split = nextUrl.split(" ");
    let time;
    if (split[0] === "Sun") {
      console.log(skipSundayNext);
      setDate(
        moment(skipSundayNext, "MM DD YYYY").format("dddd, MMMM Do YYYY")
      );
      time = moment(skipSundayNext, "MM DD YYYY").format("ddd MMM D YYYY");
    } else {
      setDate(moment(nextUrl, "MM DD YYYY").format("dddd, MMMM Do YYYY"));
      time = moment(nextUrl, "MM DD YYYY").format("ddd MMM D YYYY");
    }
    onClickDay(time);
  };

  const handleClick = async (event) => {
    const { value } = event.target;
    const appointmentBooking = {
      date: date,
      time: value,
    };
    setBooking(appointmentBooking);
    console.log(appointmentBooking);
    setRequest(true);
    setStepFive(false);
    setStepSix(true);
    setLoading(false);
  };

  if (loading) return null;

  if (stepSix)
    return (
      <>
        <AdminNav/>
        <div className="newUser container">
          <div className="container free-form text-light">
            <div className="row formRow">
              <h5 className="text-center login-header mt-3 text-dark mb-3">
                Confirm Time-Slot Removal
              </h5>
            </div>
            <div className="row formRow">
              <p className="text-center mt-3 text-dark mb-3">
                {`Date: ${booking.time + ", " + booking.date}`}
              </p>
            </div>
            <div className="row text-center my-3 ">
              <button
                className="btn sub-btn sub-btn-danger"
                type="click"
                onClick={(e) => handleFormSubmit(e)}
                name="action"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    );

  if (stepFive)
    return (
      <>
        <>
        <AdminNav/>
          <div className="newUser container">
            <div className="container free-form text-light">
              <div className="container timeContainer">
                <div className="row text-center">
                  <div className="col-3">
                    <button
                      className="btn btn-date"
                      onClick={() => BeforeDate()}
                    >
                      {beforeDate}
                    </button>
                  </div>
                  <div className="col-6">
                    <h1 className="dateTitle">{date}</h1>
                  </div>
                  <div className="col-3">
                    <button className="btn btn-date" onClick={() => NextDate()}>
                      {nextDate}
                    </button>
                  </div>
                </div>
                <div className="row text-center">
                  <p className="availableDates">AVAILABLE TIMES:</p>
                </div>
                <div className="row">
                  <ul className="timeSlots">
                    <h5 className="noAppointments">{alert.message}</h5>
                    {newTimeSlots.map((response) => {
                      if (loading) return <h1>loading...</h1>;
                      if (!loading)
                        return (
                          <li>
                            <button
                              type="btn"
                              className="hourBtn before"
                              key={response}
                              value={response}
                              onClick={(e) => handleClick(e)}
                            >
                              {response}
                            </button>
                          </li>
                        );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );

  return (
    <>
      <AdminNav/>
      <div className="newUser container">
        <div className="container free-form text-light">
          <div className="row formRow">
            <h5 className="text-center login-header mt-3 text-dark mb-3">
              Please choose the date of the timeslot you wish to remove.
            </h5>
          </div>
          <div className="row calendarBox">
            <Calendar
              className="calendar tileDisabled"
              onChange={onChange}
              onClickDay={onClickDay}
              value={value}
              minDate={new Date()}
              tileDisabled={({ activeStartDate, date, view }) =>
                date.getDay() === 0
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoveTime;
