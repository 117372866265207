import React, {useState, useEffect} from "react";
// import { Parallax, Background } from 'react-parallax';
import './blog.css';
import NavTabs from "../../utils/NavTabs";
import API from "../../utils/API";
import SubForm from "../../components/Forms/SubForm";

const Blog = () => {

  const [displayExItems, setDisplayExItems] = useState([]);

  useEffect(() => {
    initializeMenu();
  }, []);

  const initializeMenu = () => {
    API.getBlogs()
      .then((response) => {
        setDisplayExItems(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
    <NavTabs/>
    <div className="blog-header text-center mt-3 pt-3">
                      <h1>Rosestone Roofing Blog</h1>
    </div>
    <div className="container blog-post-container">
    {displayExItems.map(response => {
      return (
    <>
                    <a className="blog-a" href={`/blog/${response._id}`}>
                    <div className="row blog-row" key={response._id}>
                      <div className="col-md-4">
                        <img src={response.image} className="blog-img"/>
                      </div>
                      <div className="col-md-8">
                        <h1 className="roof-header">{response.heading}</h1>
                        <div className="row blog-text-row">
                          <div className="col-11 remove-r">
                            <p className="blog-text">{response.text}</p>
                          </div>
                          <div className="col-1 remove-l">
                                   <p className="blog-dots">...</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="blog-author">{response.author}</p>
                            <p className="blog-date">{response.date}</p>
                          </div>
                          <div className="col-6 ">
                            <p className="blog-continue">Click to continue reading</p>
                          </div>
                        </div>
                      </div>
                      </div>
                      </a>
                      </>
    );
    })}
    </div>
    <div className="row">
    <SubForm/>
    </div>
    </>
  );
};

export default Blog;
