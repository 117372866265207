import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./MyEx.css";
import ExRow from "./ExRow";
import API from "../../../utils/API";


const MyExamples = () => {
  const [exItems, setExItems] = useState([]);
  const [displayExItems, setDisplayExItems] = useState([]);

  useEffect(() => {
    initializeMenu();
  }, []);

  const initializeMenu = () => {
    API.getBlogs()
      .then((response) => {
        setExItems(response.data);
        setDisplayExItems(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    API.deleteBlog(id)
      .then((res) => initializeMenu())
      .catch((err) => console.log(err));
  };

  // const handleReset = () => {
  //   setDisplayExItems(exItems);
  // };


  return (
    <>
      <div className="container">
        <div className="adminMenu">
          <div className="row">
            
            
          
              {/* <div className="col-sm-3 input-field menuFilterButtons">
                <select
                  id="filterTerm"
                  className="btn newExBtn"
                  name="category"
                  defaultValue={`Default`}
                  onChange={(e) => {
                    const filteredItems = exItems.filter(
                      (item) => {
                        return item.category.includes(e.target.value);
                      }
                    );
                    setDisplayExItems(filteredItems);
                  }}
                >
                  <option value='Default' disabled>
                    Category
                  </option>
                  <option value="">All</option>
                  <option value="Social">Social</option>
                  <option value="Picture">Picture</option>
                  <option value="Graphic">Graphic</option>
                </select>
              </div> */}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <table className="exTable">
                <thead>
                  <tr>
                    <th>IMAGE</th>
                    <th>HEADING</th>
                    <th>TEXT</th>
                    <th>AUTHOR</th>
                    
                    <th>DELETE</th>
                  </tr>
                </thead>

                <tbody>
                  {displayExItems.map(
                    ({ _id,  image, heading, text, author }) => (
                      <ExRow
                        _id={_id}
                        image={image}
                        heading={heading}
                        text={text}
                        author={author}
                        handleDelete={handleDelete}
                      />
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyExamples;
